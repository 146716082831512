/* Position text in the top-left corner */
.topleft {
  position: absolute;
  top: 0;
  left: 16px;
}

/* Position text in the bottom-left corner */
.bottomleft {
  position: absolute;
  bottom: 0;
  left: 16px;
}

/* Position text in the middle */
.middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
  flex-direction: column;
}

/* Style the <hr> element */
hr {
  margin: auto;
  background-color: inherit;
  border-color: black;
  width: 40%;
}

div {
  color: black;
}

a {
  color: inherit;
  margin: 20px;
  text-decoration: none;
}

a:link,
a:visited {
  color: inherit; /* Ensures no blue or purple for unvisited or visited links */
}

a:hover,
a:active {
  color: inherit; /* Ensures no hover or active link color changes */
}

h1 {
  margin: 20px;
}


.bgimg {
  background-color: rgb(194, 85, 17);
  width: 100vw;
  height: 100vh;
}