.teams-container {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.team-card {
  width: 250px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
}

.team-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-header {
  text-align: center;
}

.team-image {
  width: 250px;
  height: 250px;
  object-fit: contain;
  object-position: center;
  border-radius: 4px;
}

.team-name {
  font-size: 1.5rem;
  margin-top: 5px;
  margin-bottom: 5px;
  word-wrap: break-word;
}

.card-description {
  text-align: center;
  font-size: 1rem;
  margin-top: 10px;
  margin-bottom: 5px;
}

.card-description p {
  margin: 0;
}

.card-description a {
  color: #0070f3;
  text-decoration: none;
}

.card-description a:hover {
  text-decoration: underline;
}
