.carousel-container {
    width: 100%;
    /* Subtract header (80px) and footer (60px) heights from viewport height */
    height: calc(100vh - 140px);
    position: relative;
    overflow: hidden;
}

.carousel {
    width: 100%;
    height: 100%;
    position: relative;
}

.carousel-track {
    display: flex;
    height: 100%;
    transition: transform 0.5s ease-in-out;
}

.carousel-slide {
    min-width: 100%;
    height: 100%;
}

.carousel-slide img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 1rem;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    z-index: 3;
    transition: background 0.3s;
}

.carousel-button:hover {
    background: rgba(0, 0, 0, 0.8);
}

.prev {
    left: 1rem;
}

.next {
    right: 1rem;
}

.carousel-dots {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #ccc;
    margin: 0 5px;
    cursor: pointer;
}

.dot.active {
    background: #333;
}

@media (max-width: 768px) {
    .carousel-button {
        padding: 0.5rem;
        font-size: 1rem;
    }

    .carousel-container {
        margin: 0 auto;
    }

} 