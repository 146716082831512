.home-page {
    height: calc(100vh - 140px);
    margin: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}


@media (max-width: 768px) {

} 