.footer {
  background-color: #333;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}

.footer-icons {
  display: flex;
  gap: 1.25rem;
  margin-right: 1.25rem;
}

.footer a {
  color: white;
  transition: color 0.3s ease;
}

.footer a:hover {
  color: #aaa;
}